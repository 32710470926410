<template>
  <transition
    name="rr-modal-transition"
    tag="div"
    mode="out-in"
  >
    <div
      class="rr-modal"
      v-show="isActive"
      :class="[
        align ? 'text-' + align : '',
        noTransition && 'rr-modal_no-transition',
        size ? `rr-modal_${size}` : '',
      ]"
    >
      <div
        class="rr-modal__overlay"
        v-if="!noOverlay"
        @click="close">
      </div>
      <div class="rr-modal__window">
        <div class="rr-modal__close">
          <RrButton
            v-if="closable"
            variant="secondary"
            icon-left="close"
            size="lg"
            shape="square"
            @click="close"
          >
          </RrButton>
        </div>
        <div class="rr-modal__container">
          <div class="rr-modal__item">
            <div
              class="rr-modal__icon"
              :class="this.state && `color-${this.state}`"
              v-if="iconName || $slots.icon"
            >
              <Icon :name="iconName" v-if="iconName"></Icon>
              <slot name="icon"></slot>
            </div>
            <div class="rr-modal__img" v-if="img || $slots.img">
              <img :src="img" alt="">
              <slot name="img"></slot>
            </div>
            <h3 class="rr-modal__title h3_mb" v-if="title" v-html="title"></h3>
            <div class="rr-modal__subtitle" v-if="subtitle" v-html="subtitle"></div>
            <div class="rr-modal__subtitle" v-if="$slots.subtitle">
              <slot name="subtitle"></slot>
            </div>
          </div>
          <div class="rr-modal__item" v-if="$slots.default">
            <slot :close="close"></slot>
          </div>
          <div class="rr-modal__note" v-if="$slots.note">
            <slot name="note"></slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Icon from '@rr-component-library/icon/src/main';
import RrButton from '@rr-component-library/button/src/main';

export default {
  name: 'Modal',
  components: {
    Icon,
    RrButton,
  },
  props: {
    name: {
      type: String,
    },
    title: String,
    subtitle: String,
    icon: String,
    img: String,
    state: {
      type: String,
      validator(value) {
        return ['danger', 'warning', 'success'].indexOf(value) !== -1;
      },
    },
    align: {
      type: String,
      default: 'center',
    },
    active: {
      type: Boolean,
      default: false,
    },
    closable: {
      type: Boolean,
      default: true,
    },
    noTransition: {
      type: Boolean,
      default: false,
    },
    noOverlay: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: '',
      validator(value) {
        return ['', 'lg'].indexOf(value) !== -1;
      },
    },
  },
  data() {
    return {
      isActive: this.active,
      icons: {
        success: 'check-circle',
        warning: 'warning',
        danger: 'exclamation-circle',
      },
    };
  },
  computed: {
    iconName() {
      if (this.icon) {
        return this.icon;
      }
      return this.icons[this.state];
    },
  },
  model: {
    prop: 'active',
    event: 'toggle',
  },
  methods: {
    open() {
      this.$emit('open');
      this.isActive = true;
    },
    close() {
      if (!this.closable) {
        return;
      }
      const event = new Event('click', { cancelable: true });
      this.$emit('close', event);
      if (event.defaultPrevented) {
        return;
      }
      this.isActive = false;
      setTimeout(() => {
        this.$emit('afterClose');
      }, 200);
    },
    toggleOverflow() {
      const modals = this.$root.$modals;
      if (this.isActive) {
        modals.active = this.name;
        document.body.style.overflowY = 'hidden';
        document.body.classList.add('has-modal');
        return;
      }
      if (modals.active === this.name) {
        modals.active = null;
        document.body.style.overflowY = '';
        document.body.classList.remove('has-modal');
      }
    },
  },
  watch: {
    active(val) {
      this.isActive = val;
    },
    isActive(val) {
      if (val) {
        setTimeout(() => {
          this.$el.scrollTo(0, 0);
        });
      }
      this.toggleOverflow();
    },
    $route(newRoute, oldRoute) {
      if (oldRoute.name && newRoute.name !== oldRoute.name && this.isActive) {
        this.close();
      }
    },
  },
  created() {
    if (!this.$root.$modals) {
      this.$root.$modals = {};
    }
    if (this.name) {
      this.$root.$modals[this.name] = this;
    }
  },
  beforeDestroy() {
    this.isActive = false;
  },
};
</script>

<style lang="scss" scoped>
@import "./Modal";

.rr-modal {
  transition: $transition-default;
  transition-property: opacity, visibility;

  &_no-transition {
    transition: none;
  }
}

.rr-modal-transition-enter,
.rr-modal-transition-leave-to {
  opacity: 0;
}
</style>
