<template>
    <div class="divider" :class="dividerClass">
        <div class="divider__line" v-if="position !== 'left'"></div>
        <div class="divider__text">
            <slot />
        </div>
        <div class="divider__line" v-if="position !== 'right'"></div>
    </div>
</template>

<script>
export default {
    name: "FormDivider",
    props: {
        position: {
            type: String,
            default: "left"
        }
    },
    computed: {
        dividerClass() {
            const { position } = this;
            const dividerClass = {};

            if (position === "left") {
                dividerClass["divider_text-position_left"] = true;
            }

            if (position === "right") {
                dividerClass["divider_text-position_right"] = true;
            }

            return dividerClass;
        }
    }
};
</script>

<style lang="scss" scoped>
@import "./FormDivider.scss";
</style>
