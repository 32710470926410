<template>
  <div class="disabled-cookie-block">
    <div class="disabled-cookie-block__content">
      <h1 class="disabled-cookie-block__title" v-html="$t('title')"></h1>
      <div v-html="$t('text')"></div>
      <RrButton
        class="disabled-cookie-block__button"
        variant="primary"
        size="lg"
        type=""
        :text="$t('button')"
        wide
        @click="retry"
      ></RrButton>
    </div>
  </div>
</template>

<script>
import RrButton from '@rr-component-library/button/src/main';

export default {
  name: 'DisabledCookieBlock',
  components: {
    RrButton,
  },
  i18n: {
    messages: {
      ru: {
        title: 'Отключена поддержка файлов cookie',
        text: 'Включите поддержку файлов cookie в настройках браузера и&nbsp;повторите попытку.',
        button: 'Повторить попытку',
      },
      en: {
        title: 'Disabled support for cookies',
        text: 'Please enable cookies in your browser settings and try again.',
        button: 'Try Again',
      },
    },
  },
  methods: {
    retry() {
      const event = new Event('click', { cancelable: true });
      this.$emit('click:retry', event);
      if (event.defaultPrevented) {
        return;
      }
      window.location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./DisabledCookieBlock";
</style>
