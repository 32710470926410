<script>
export default {
    props: {
        modelValue: {
            default: ""
        }
    },
    created() {
        this.$emit("input", {
            value: this.modelValue,
            isValid: !this.$v.$error
        });
    },
    data() {
        return {
            isShowError: false
        };
    },
    methods: {
        touch() {
            this.isShowError = true;
            this.$v.$touch();
        },
        getStateByFlags(hasError, isDirty) {
            if (!isDirty || !this.isShowError) {
                return "";
            }

            if (hasError) {
                return "incorrect";
            }

            return "correct";
        },
        getState(propName) {
            return this.getStateByFlags(
                this.$v[propName].$error || this.$v[propName].$invalid,
                this.$v[propName].$dirty
            );
        }
    },
    computed: {
        isValid() {
            return !this.$v.$error;
        },
        isDirty() {
            return this.$v.$dirty;
        }
    }
};
</script>

<style>
</style>