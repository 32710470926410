<template lang="pug">
  .modal(:class="[align ? 'text-' + align : '', isActive ? 'active' : '']")
    .modal__overlay.overlay(@click="close")
    .modal__window
      button.link.modal__close(type="button" @click="close" v-if="closable && !hideClose")
        icon(icon-name='close' w="20" h="20")
      .modal__container
        slot
</template>

<script>
export default {
  name: 'Modal',
  data () {
    return {
      isActive: false
    }
  },
  props: {
    name: {
      type: String
    },
    title: String,
    subtitle: String,
    align: String,
    _isActive: {
      type: Boolean,
      default: false
    },
    closable: {
      type: Boolean,
      default: true
    },
    hideClose: Boolean
  },
  methods: {
    open () {
      this.$emit('beforeOpen')
      this.isActive = true
      document.body.style.overflowY = 'hidden'
      document.body.classList.add('has-modal')
    },
    close () {
      if (!this.closable) {
        return
      }
      const vm = this
      this.isActive = false
      document.body.style.overflowY = ''
      document.body.classList.remove('has-modal')

      setTimeout(function () {
        vm.$emit('afterClose')
      }, 200)
    }
  },
  watch: {
    $route () {
      if (this.isActive) {
        this.close()
      }
    }
  },
  created () {
    if (this._isActive) {
      this.open()
    }
    if (!this.$root.$modals) {
      this.$root.$modals = {};
    }
    if (this.name) {
      this.$root.$modals[this.name] = this
    }
  }
}
</script>

<style lang="scss">
  @import "@/assets/scss/components/modal";
</style>
