<script>
export default {
    name: "ShakeErrorInputMixin",
    methods: {
        shakeErrorInputs() {
            const { shakedInputArea } = this.$refs;
            if (shakedInputArea) {
                let inputsArea = shakedInputArea.$el
                    ? shakedInputArea.$el
                    : shakedInputArea;

                const inputs = inputsArea.getElementsByClassName(
                    "input_state_incorrect"
                );
                inputs.forEach(input => {
                    input.classList.add("shake");
                });

                setTimeout(() => {
                    inputs.forEach(input => {
                        input.classList.remove("shake");
                    });
                }, 860);
            }
        }
    }
};
</script>

<style lang="scss">
@import "./ShakeErrorInputMixin.scss";
</style>
