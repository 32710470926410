<template>
    <label :class="componentClass">
        <div class="radio__value">
            <input
                type="radio"
                :checked="shouldBeChecked"
                :value="value"
                :name="name"
                @change="updateInput"
            />
            <div class="radio__check"></div>
        </div>
        <div class="radio__label">
            <slot></slot>
        </div>
    </label>
</template>
<script>
export default {
    model: {
        prop: "modelValue",
        event: "change"
    },
    props: {
        value: null,
        modelValue: {
            default: ""
        },
        name: String,
        state: {
            type: String,
            default: null,
            required: false
        }
    },
    computed: {
        shouldBeChecked() {
            return this.modelValue == this.value;
        },
        componentClass: function() {
            return "radio " + this.stateClass;
        },
        stateClass: function() {
            if (this.state) {
                return "input_state_" + this.state;
            }
            return "";
        }
    },
    methods: {
        updateInput() {
            this.$emit("change", this.value);
        }
    }
};
</script>

<style lang="scss">
@import "radio.scss";
</style>
