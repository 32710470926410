<template>
  <svg :class="svgClass" :width="w" :height="h" aria-hidden="true">
    <use :xlink:href="name"></use>
  </svg>
</template>

<script>
const requireAll = requireContext => requireContext.keys().map(requireContext)
const req = require.context('../assets/img/svg', false, /\.svg$/)
requireAll(req)

export default {
  name: 'Icon',
  props: {
    iconName: {
      type: String,
      required: true
    },
    className: {
      type: String,
      default: ''
    },
    w: {
      type: String,
      default: null
    },
    h: {
      type: String,
      default: null
    }
  },
  computed: {
    name () {
      const icon = this.iconName
      return icon ? `#icon-${icon}` : ''
    },
    svgClass () {
      const className = this.className
      return className ? `svg-icon ${className}` : 'svg-icon'
    }
  }
}
</script>

<style>
  .svg-icon {
    /*width: 1em;*/
    /*height: 1em;*/
    fill: currentColor;
    overflow: hidden;
  }
</style>
