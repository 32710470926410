<template>
    <div class="labeled-form-control" :class="formClass">
        <div class="labeled-form-control__label-left" v-if="$slots.labelLeft">
            <slot name="labelLeft" />
        </div>
        <div class="labeled-form-control__control-wrap">
            <div class="labeled-form-control__label-top-wrapper">
                <div class="labeled-form-control__label-top" v-if="$slots.labelTop">
                    <slot name="labelTop" />
                </div>
                <div class="labeled-form-control__label-top-rigth" v-if="$slots.labelTopRigth">
                    <slot name="labelTopRigth" />
                </div>
            </div>
            <div class="labeled-form-control__control" v-if="$slots.default">
                <slot name="default" />
            </div>
            <slot name="controlWrap" />
            <div class="labeled-form-control__error" v-if="$slots.error">
                <slot name="error" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "LabeledFormControl",
    props: {
        gapSize: {
            type: Number
        },
        fixedGrid: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        formClass() {
            const { $slots } = this;
            let labelDir = {};

            if ($slots.labelTop) {
                labelDir["labeled-form-control_label_top"] = true;
            }

            if ($slots.labelTopRight) {
                labelDir["labeled-form-control_label_top-right"] = true;
            }

            if ($slots.labelLeft) {
                labelDir["labeled-form-control_label_left"] = true;
            }

            if (this.fixedGrid) {
                labelDir["labeled-form-control_fixed-grid"] = true;
            }

            return labelDir;
        }
    }
};
</script>

<style lang="scss">
@import './LabeledFormControl.scss';
</style>
