<template>
    <div class="alert" :class="`${classState} ${classType}`">
        <div class="alert__wrap">
            <div class="alert__icon" :class="{'alert__icon_transparent': $scopedSlots.icon}" v-if="hasIcon">
                <IconBase v-if="$scopedSlots.icon">
                    <slot name="icon"></slot>
                </IconBase>
            </div>
            <div class="alert__message">
                <div class="alert__title" v-if="title">{{title}}</div>
                <div class="alert__text" v-if="text" v-html="text"></div>
            </div>
            <div class="alert__action" @click="$emit('close')" v-if="actionLabel">
                <div class="alert-action__icon"></div>
                <div class="alert-action__label">{{actionLabel}}</div>
            </div>
            <div class="alert__action" v-if="rigthLabel">
                <b>{{rigthLabel}}</b>
            </div>
        </div>
    </div>
</template>

<script>
import IconBase from "./../../component-icons/IconBase";

export default {
    name: "BaseAlert",
    components: {
        IconBase,
    },
    props: {
        action: {
            type: Function,
            default: null,
            required: false
        },
        actionLabel: {
            type: String,
            default: null,
            required: false
        },
        rigthLabel: {
            type: String,
            default: null,
            required: false
        },
        text: {
            type: String,
            default: null,
            required: false
        },
        title: {
            type: String,
            default: null,
            required: false
        },
        hasIcon: {
            type: Boolean,
            default: false,
            required: false
        },
        state: {
            type: String,
            default: "danger",
            required: false
        },
        isWide: {
            type: Boolean,
            default: false,
            required: false
        }
    },
    computed: {
        classState: function() {
            return "alert_state_" + this.state;
        },
        classType() {
            if (this.isWide) {
                return "alert_type_wide";
            }

            const types = [];
            const { hasIcon, title, text, actionLabel } = this;

            if (hasIcon) types.push("icon");
            if (title) types.push("title");
            if (text) types.push("text");
            if (actionLabel) types.push("action");

            return "alert_type_" + types.join("-");
        }
    }
};
</script>

<style lang="scss">
@import "alert.scss";
</style>
