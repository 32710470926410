<template>
    <LabeledFormControl class="form-control_type_password">
        <template v-if="position === 'top'" v-slot:labelLeft>
            {{label}}
        </template>
        <template v-if="position === 'left'" v-slot:labelTop>
            {{label}}
        </template>
        <template v-slot:error>
            <div v-if="isShowError">
                <div v-if="$v.passwordValue.password === false">{{includedValidation.password}}</div>
                <div v-if="$v.passwordValue.required === false && $v.passwordValue.$dirty">{{includedValidation.required}}</div>
                <div v-if="$v.passwordValue.minLength === false && $v.passwordValue.$dirty">{{includedValidation.minLength}}</div>
            </div>
        </template>		
        <template v-if="resetLinkText" v-slot:labelTopRigth>
            <a :href="resetLinkUrl" target="_blank">
                {{resetLinkText}}
            </a>
        </template>
        <template v-slot:default>
            <base-input :type="isVisible ? 'text' : 'password'"
                        name="secondaryPhoneNumber"
                        v-model="$v.passwordValue.$model"
                        :modelValue="modelValue"
                        :placeholder="placeholder"
                        @input="$emit('input', { value: $event, isValid: isValid, isDirty: isDirty })"
                        :hideIcon="hideIcon"
                        :state="passwordState">
                <template v-slot:iconRight>
                    <icon-base v-if="isLoading"
                               name="spinner"
                               width="18"
                               height="18"
                               iconColor="#010101"
                               strokeColor="#010101"
                               :opacity="0.45"
                               :strokeOpacity="0.45">
                        <icon-spinner />
                    </icon-base>
                </template>
                <template v-slot:actionRigth>
                    <div class="form-control_type_password__action">
                        <icon-base v-if="!isVisible"
                                   v-on:click.native="changeVisibility"
                                   name="invisible"
                                   width="18"
                                   height="18"
                                   iconColor="#010101"
                                   strokeColor="#010101"
                                   :opacity="0.45"
                                   :strokeOpacity="0.45">
                            <icon-eye-invisible />
                        </icon-base>
                        <icon-base v-if="isVisible"
                                   v-on:click.native="changeVisibility"
                                   name="visible"
                                   width="18"
                                   height="18"
                                   iconColor="#010101"
                                   strokeColor="#010101"
                                   :opacity="0.45"
                                   :strokeOpacity="0.45">
                            <icon-eye />
                        </icon-base>
                    </div>
                </template>
            </base-input>
        </template>
    </LabeledFormControl>
</template>

<script>
    import LabeledFormControl from "./../LabeledFormControl";
    import BaseInput from "./../BaseInput";
    import { required, minLength } from "vuelidate/lib/validators";
    import ControlValidationMixin from "./../../mixins/ControlValidationMixin";
    import IconBase from "./../../component-icons/IconBase.vue";
    import IconSpinner from "./../../component-icons/IconSpinner.vue";
    import IconEye from "./../../component-icons/Application/IconEye.vue";
    import IconEyeInvisible from "./../../component-icons/Application/IconEyeInvisible.vue";

    export default {
        name: "PasswordControl",
        mixins: [ControlValidationMixin],
        components: {
            LabeledFormControl,
            BaseInput, IconBase,
            IconSpinner,
            IconEye,
            IconEyeInvisible
        },
        props: {
            label: String,
            placeholder: String,
            resetLinkText: String,
            resetLinkUrl: String,
            includedValidation: Object,
            position: {
                type: String,
                default: "top"
            },
            isValidated: {
                type: Boolean,
                default: false
            },
            isRequired: {
                type: Boolean,
                default: false
            },
            isLoading: Boolean,
            modelValue: {
                type: String,
                default: ""
            },
            minLength: {
                type: Number,
                required: false,
                default: 0
            },
            hideIcon: {
                type: Boolean,
                default: false
            }
        },
        model: {
            modelValue: "passwordValue"
        },
        data() {
            return {
                passwordValue: this.modelValue,
                isVisible: false
            };
        },
        validations() {
            const passwordValue = {};

            if (!this.includedValidation) {
                return { passwordValue };
            }

            const {
                required: includedRequired,
                minLength: minLengthRequired,
            } = this?.includedValidation;

            if (includedRequired) {
                passwordValue.required = required;
            }

            if (minLengthRequired && this.minLength > 0) {
                passwordValue.minLength = minLength(this.minLength);
            }

            return { passwordValue };
        },
        computed: {
            passwordState() {
                if (!this.includedValidation) {
                    return "";
                }
                return this.getState("passwordValue");
            }
        },
        methods: {
            touch() {
                this.isShowError = true;
                this.$v.$touch();
                this.$emit("input", {
                    value: this.passwordValue,
                    isValid: !this.$v.$error
                });
            },
            changeVisibility() {
                this.isVisible = !this.isVisible;
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import './PasswordControl.scss';
</style>
