<template>
    <button class="button" @click="action" :class="buttonClass">
        <div class="button__icon" v-if="$slots.iconLeft" :style="isLoading">
            <slot name="iconLeft" />
        </div>
        <div class="button__text" :style="isLoading">
            <slot />
        </div>
        <div class="button__icon" v-if="$slots.iconRight" :style="isLoading">
            <slot name="iconRight" />
        </div>

        <div class="button__spinner" v-if="loading">
            <icon-base
                name="spinner"
                iconColor="#ffffff"
                strokeColor="#ffffff"
                :width="18"
                :height="18"
            >
                <icon-spinner />
            </icon-base>
        </div>
    </button>
</template>

<script>
import IconSpinner from "./../../component-icons/IconSpinner.vue";
import IconBase from "./../../component-icons/IconBase.vue";

export default {
    props: {
        action: {
            type: Function,
            default: new Function(),
            required: false,
        },
        size: {
            type: String,
            default: "medium",
        },
        family: {
            type: String,
            default: "primary",
        },
        type: {
            type: String,
            default: "standart",
        },
        color: {
            type: String,
            default: "primary",
        },
        loading: Boolean,
        disabled: Boolean,
    },
    name: "BaseButton",
    components: { IconSpinner, IconBase },
    computed: {
        isLoading() {
            return this.loading ? "visibility:hidden" : "";
        },
        buttonClass() {
            const { disabled, size, type, family, color } = this;
            return {
                button_state_disabled: disabled,
                [`button_size_${size}`]: true,
                [`button_type_${type}`]: true,
                [`button_family_${family}`]: true,
                [`button_color_${color}`]: true,
                [`button_state_loading`]: this.loading,
            };
        },
    },
};
</script>

<style lang="scss">
@import "button.scss";
</style>