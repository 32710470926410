<template>
    <div class="input" :class="componentClass">
        <div class="input__icon" v-if="$slots.iconLeft">
            <slot name="iconLeft" />
        </div>
        <input
            :type="type"
            class="input__text"
            :placeholder="placeholder"
            :name="name"
            :id="name"
            :value="modelValue"
            placeholderChar="X"
            @input="$emit('input', $event.target.value)"
            @blur="$emit('blur', $event.target.value)"
            @keydown.enter="$emit('enter', $event.target.value)"
            :readonly="readonly"
            :disabled="disabled"
        />
        <div class="input__icon input__icon-right" v-if="$slots.iconRight">
            <slot name="iconRight" />
        </div>
        <div class="input__icon input__icon-state input__icon-right" v-if="state && !hideIcon"></div>
        <div class="input__icon input__icon-right action" v-if="$slots.actionRigth && !loading">
            <slot name="actionRigth" />
        </div>
        <div class="input__icon input__icon-right" v-if="loading">
            <IconBase :height="18" :width="18">
                <IconSpinner />
            </IconBase>
        </div>
    </div>
</template>

<script>
import IconBase from "./../../component-icons/IconBase";
import IconSpinner from "./../../component-icons/IconSpinner";

export default {
    name: "BaseInput",
    components: { IconBase, IconSpinner },
    props: {
        placeholder: {
            type: String,
            default: "",
            required: false
        },
        name: {
            type: String,
            default: null,
            required: false
        },
        state: {
            type: String,
            default: null,
            required: false
        },
        modelValue: {
            default: ""
        },
        type: {
            required: false,
            default: "text"
        },
        hideIcon: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
    },
    model: {
        prop: "modelValue"
    },
    computed: {
        componentClass: function() {
            return this.typeClass + " " + this.stateClass;
        },
        stateClass: function() {
            if (this.state) {
                return "input_state_" + this.state;
            }
            return "";
        },
        typeClass: function() {
            const { state } = this;
            const hasRightIcon = this.$slots.iconRight || state;
            const hasLeftIcon = !!this.$slots.iconLeft;
            let typeClass = "input_type_";
            let type;

            if (hasLeftIcon && hasRightIcon) {
                type = "both";
            } else if (hasLeftIcon) {
                type = "left";
            } else if (hasRightIcon) {
                type = "right";
            } else {
                type = "base";
            }

            return typeClass + type;
        }
    },
    methods: {
        getImageStyle(iconUrl) {
            return { backgroundImage: `url("${iconUrl}")` };
        },
        touch() {
            this.$emit("input", this.modelValue);
        }
    }
};
</script>

<style lang="scss">
@import "input.scss";
</style>
