<template>
    <div class="form-page">
        <div class="form-page__header">
            <div class="form-page__title" v-if="$slots.titleLeft || $slots.titleRight">
                <div class="form-page__title-item-left">
                    <slot name="titleLeft" />
                </div>
                <div class="form-page__title-item-right">
                    <slot name="titleRight" />
                </div>
                <div class="form-page__title-description" v-if="$slots.description">
                    <slot name="description" />
                </div>
            </div>
            <div class="form-page__content">
                <slot name="default" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "FormPage",
};
</script>

<style lang="scss">
@import "./FormPage.scss";
</style>