<template>
    <div class="checkbox checkbox_type_check" v-if="withoutLabel">
        <input
            type="checkbox"
            class="checkbox__value"
            :name="id"
            :id="id"
            :checked="checked"
            @change="$emit('change', $event.target.checked)"
        />
        <div class="checkbox__label">
            <slot></slot>
        </div>
    </div>
    <label class="checkbox checkbox_type_check" v-else>
        <input
            type="checkbox"
            class="checkbox__value"
            :name="id"
            :id="id"
            :checked="checked"
            @change="$emit('change', $event.target.checked)"
        />
        <div class="checkbox__label">
            <slot></slot>
        </div>
    </label>
</template>

<script>
export default {
    name: "BaseCheckbox",
    props: {
        checked: {
            type: Boolean,
            default: false
        },
        id: {
            type: String,
            default: null
        },
        withoutLabel: {
            type: Boolean,
            default: false
        }
    },
    model: {
        prop: "checked",
        event: "change"
    }
};
</script>

<style lang="scss">
@import "checkbox.scss";
</style>