<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        :width="width"
        :height="height"
        viewBox="0 0 24 24"
        :aria-labelledby="iconName"
        role="presentation"
    >
        <g
            :fill="iconColor"
            :stroke="strokeColor"
            :fill-opacity="opacity"
            :stroke-opacity="strokeOpacity"
        >
            <slot />
        </g>
    </svg>
</template>

<script>
export default {
    props: {
        iconName: {
            type: String,
            default: "box"
        },
        width: {
            type: [Number, String],
            default: 24
        },
        height: {
            type: [Number, String],
            default: 24
        },
        iconColor: {
            type: String,
            default: "currentColor"
        },
        strokeColor: {
            type: String,
            default: ""
        },
        opacity: {
            type: Number,
            default: 1
        },
        strokeOpacity: {
            type: Number,
            default: 1
        }
    }
};
</script>

<style scoped>
</style>