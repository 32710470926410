<template>
  <RrAlert
    v-show="isActive"
    class="disabled-cookie-alert"
    :text="$t('text')"
    :action-text="$t('button')"
    state="warning"
    wide
    stacked
    closable
    style="z-index: 99;"
  ></RrAlert>
</template>

<script>
import RrAlert from "@rr-component-library/alert/src/main";

export default {
  name: 'DisabledCookieAlert',
  components: {
    RrAlert,
  },
  i18n: {
    messages: {
      ru: {
        text: 'В браузере отключена поддержка файлов cookie. Файлы cookie нужны для&nbsp;стабильной работы личного кабинета: авторизации и&nbsp;других действий. Включите&nbsp;поддержку файлов Cookie в&nbsp;настройках браузера.',
        button: 'Понятно',
      },
      en: {
        text: 'Cookies are disabled in the browser. Cookies are necessary for the stable operation of your personal account: authorization and other actions. Please enable cookies in your browser settings.',
        button: 'Got It',
      },
    },
  },
  data() {
    return {
      isActive: !navigator.cookieEnabled,
    };
  },
};
</script>

<style lang="scss" scoped>
.disabled-cookie-alert ::v-deep {

  .rr-alert__col:last-child {
    flex: 0 0 auto;
    margin-left: auto;
  }

  @media (max-width: 767.9px) {
    .rr-alert__icon {
      display: none;
    }
  }
}
</style>
