<template>
    <LabeledFormControl class="form-control_type_email">
        <template v-if="position === 'top'" v-slot:labelLeft>{{label}}</template>
        <template v-if="position === 'left'" v-slot:labelTop>{{label}}</template>

        <template v-slot:error>
            <div v-if="isShowError">
                <div v-if="$v.emailValue.required === false && $v.emailValue.$dirty" v-html="includedValidation.required"></div>
                <div v-else-if="$v.emailValue.noCyrillic === false" v-html="includedValidation.noCyrillic"></div>
                <div v-else-if="$v.emailValue.email === false" v-html="includedValidation.email"></div>
                <div v-else-if="$v.emailValue.exists === false && $v.emailValue.$dirty && !isLoading" v-html="includedValidation.exists"></div>
                <div v-if="customError" v-html="customError"></div>
            </div>
        </template>

        <template v-slot:default>
            <base-input
                type="email"
                name="secondaryPhoneNumber"
                v-model="$v.emailValue.$model"
                :modelValue="modelValue"
                :placeholder="placeholder"
                @input="$emit('input', { value: $event, isValid: isValid, isDirty: isDirty })"
                :state="emailState"
                :hideIcon="hideIcon"
            >
                <template v-slot:iconRight v-if="isLoading">
                    <icon-base
                        name="spinner"
                        width="18"
                        height="18"
                        iconColor="#010101"
                        strokeColor="#010101"
                        :opacity="0.45"
                        :strokeOpacity="0.45"
                    >
                        <icon-spinner />
                    </icon-base>
                </template>
            </base-input>
        </template>
    </LabeledFormControl>
</template>

<script>
import LabeledFormControl from "./../LabeledFormControl";
import BaseInput from "./../BaseInput";
import { required, email } from "vuelidate/lib/validators";
import ControlValidationMixin from "./../../mixins/ControlValidationMixin";
import IconBase from "./../../component-icons/IconBase.vue";
import IconSpinner from "./../../component-icons/IconSpinner.vue";

import axios from "axios";

export default {
    name: "EmailControl",
    mixins: [ControlValidationMixin],
    components: { LabeledFormControl, BaseInput, IconBase, IconSpinner },
    props: {
        label: String,
        placeholder: String,
        includedValidation: Object,
        position: {
            type: String,
            default: "top"
        },
        isValidated: {
            type: Boolean,
            default: false
        },
        isRequired: {
            type: Boolean,
            default: false
        },
        isLoading: Boolean,
        modelValue: {
            type: String,
            default: ""
        },
        customError: {
            type: String,
            default: ""
        },
        hideIcon: {
            type: Boolean,
            default: false
        }
    },
    model: {
        modelValue: "emailValue"
    },
    data() {
        return {
            emailValue: this.modelValue
        };
    },
    validations() {
        const emailValue = {};

        if (!this.includedValidation) {
            return { emailValue };
        }

        const {
            email: includedEmail,
            required: includedRequired,
            exists: includedExists,
            noCyrillic: includedNoCyrillic,
        } = this?.includedValidation;

        if (includedEmail) {
            emailValue.email = email;
        }

        if (includedRequired) {
            emailValue.required = required;
        }

        if (includedExists) {
            emailValue.exists = this.existsValidation;
        }

        if (includedNoCyrillic) {
            emailValue.noCyrillic = this.noCyrillicValidation;
        }

        return { emailValue };
    },
    computed: {
        emailState() {
            if (this.customError) {
                return "incorrect";
            }
            if (!this.includedValidation || this.isLoading) {
                return "";
            }
            return this.getState("emailValue");
        }
    },
    methods: {
        noCyrillicValidation(v) {
          return !v.match(/[а-яА-ЯЁё]+/ig);
        },
        async existsValidation() {
            if (!this.$v.emailValue.required
              || !this.$v.emailValue.email
              || !this.$v.emailValue.noCyrillic
              || !this.isShowError
            ) {
                return true;
            }

            this.isLoading = true;
            let result = false;
            try {
                const checkEmailData = new FormData();
                checkEmailData.set('email', this.$v.emailValue.$model);

                const checkResult = axios
                    .post('/auth/checkEmail', checkEmailData)
                    .then(response => {
                        const { data } = response;

                        this.isLoading = false;
                        result = !data.Exists;

                        return true;
                    });

                await Promise.all([checkResult]);

                return result;
            } catch (e) {
                console.log("error on checking email on server side");
            }
            return false;
        },
        touch() {
            this.isShowError = true;
            this.$v.$touch();
            this.$emit("input", {
                value: this.emailValue,
                isValid: !this.$v.$error
            });
        },
        reset() {
            this.$v.$reset();
        }
    }
};
</script>

<style lang="scss" scoped>
@import './EmailControl.scss';
</style>
