<template>
  <div class="date-input">
    <!-- Single field : BEGIN -->
    <div class="date-input-single" v-if="isMobile">
      <RrInput
        class="date-input-single__el"
        readonly
        placeholder="XX.XX.XXXX"
        icon-right="calendar"
        :disabled="disabled"
        :inputModel="formattedInputValue"
        :mobile="mobile"
        :state="
          showErrors && validation.hasErrors ? 'error' :
          null
        "
      >
      </RrInput>
      <input
        class="date-input-single__input"
        v-model="inputModel"
        :disabled="disabled"
        type="date"
        :max="maxDate"
        :min="minDate"
      >
    </div>
    <!-- Single field : END -->
    <!-- Separate fields : BEGIN -->
    <div class="date-input-separate" v-if="!isMobile">
      <div class="date-input-separate__item">
        <RrField
          :label="$t('day')"
          label-size="sm"
          :label-for="`${id}_day`"
          style="width: 54px;"
        >
          <RrInput
            v-model="separateInputModel.day"
            v-mask="'##'"
            :id="`${id}_day`"
            type="tel"
            name="day"
            :disabled="disabled"
            placeholder="XX"
            autocomplete="off"
            no-state-icons
            :state="
              showErrors && validation.hasErrors ? 'error' :
              null
            "
            @change="onSeparateChange('day', $event.target.value)"
          >
          </RrInput>
        </RrField>
      </div>
      <div class="date-input-separate__item">
        <RrField
          :label="$t('month')"
          label-size="sm"
          :label-for="`${id}_month`"
          style="width: 54px;"
        >
          <RrInput
            v-model="separateInputModel.month"
            v-mask="'##'"
            :id="`${id}_month`"
            type="tel"
            name="month"
            :disabled="disabled"
            placeholder="XX"
            autocomplete="off"
            no-state-icons
            :state="
              showErrors && validation.hasErrors ? 'error' :
              null
            "
            @change="onSeparateChange('month', $event.target.value)"
          >
          </RrInput>
        </RrField>
      </div>
      <div class="date-input-separate__item">
        <RrField
          :label="$t('year')"
          label-size="sm"
          :label-for="`${id}_year`"
          style="width: 76px;"
        >
          <RrInput
            v-model="separateInputModel.year"
            v-mask="'####'"
            :disabled="disabled"
            :id="`${id}_year`"
            type="tel"
            name="year"
            placeholder="XXXX"
            autocomplete="off"
            no-state-icons
            :state="
              showErrors && validation.hasErrors ? 'error' :
              null
            "
            @change="onSeparateChange('year', $event.target.value)"
          >
          </RrInput>
        </RrField>
      </div>
    </div>
    <!-- Separate fields : END -->
  </div>
</template>

<script>
import { mask } from 'vue-the-mask';
import ru from './locales/ru.json';
import en from './locales/en.json';
import uz from './locales/uz.json';
import RrField from '@rr-component-library/field/src/main';
import RrInput from '@rr-component-library/input/src/main';

function dateFormatValidator(val) {
  return !val || (/^\d{4}[-](0?[1-9]|1[012])[-](0?[1-9]|[12][0-9]|3[01])$/).test(val);
}

function getMobileOperatingSystem() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  }

  return 'unknown';
}

const os = getMobileOperatingSystem();

export default {
  name: 'DateInput',
  directives: { mask },
  i18n: {
    messages: { ru, en, uz },
  },
  components: {
    RrField,
    RrInput,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      validator: dateFormatValidator,
    },
    minDate: {
      type: String,
      validator: dateFormatValidator,
    },
    maxDate: {
      type: String,
      validator: dateFormatValidator,
    },
    showErrors: {
      type: Boolean,
      default: true,
    },
    mobile: Boolean,
  },
  data() {
    return {
      separateInputValue: {
        year: null,
        month: null,
        day: null,
      },
      config: {
        day: {
          min: 1,
          max: 31,
          length: 2,
        },
        month: {
          min: 1,
          max: 12,
          length: 2,
        },
        year: {
          min: null,
          max: null,
          length: 4,
        },
      },
      validation: {
        hasErrors: false,
        required: true,
        correct: true,
        range: true,
      },
      isMobile: os === 'Android' || os === 'iOS',
    };
  },
  computed: {
    availableDates() {
      return [
        this.minDate ? new Date(this.minDate) : -Infinity,
        this.maxDate ? new Date(this.maxDate) : Infinity,
      ];
    },
    inputModel: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    separateInputModel: {
      get() {
        const { value } = this;
        if (value == null) {
          return this.separateInputValue;
        }
        const dateArr = (value && value.split('-')) || [];
        return {
          year: dateArr[0] || null,
          month: dateArr[1] || null,
          day: dateArr[2] || null,
        };
      },
      set({ day, month, year }) {
        this.separateInputValue = { day, month, year };
        const value = day && month && year
          ? `${year}-${month}-${day}`
          : null;
        this.$emit('input', value);
      },
    },
    formattedInputValue() {
      if (!this.inputModel) {
        return '';
      }
      const date = new Date(this.inputModel);
      const dateArr = [
        `0${date.getDate()}`.slice(-2),
        `0${date.getMonth() + 1}`.slice(-2),
        date.getFullYear()
      ]
      return dateArr.join('.');
    },
  },
  methods: {
    onSeparateChange(name, value) {
      const { length } = this.config[name];

      const model = this.separateInputModel;

      if (!value) {
        model[name] = value;
        this.separateInputModel = model;
        return;
      }

      let val = +value;

      while (val && `${val}`.length < length) {
        val = `0${val}`;
      }

      model[name] = val;
      this.separateInputModel = model;
    },
    validateDate(value) {
      this.validation.required = !!value;
      this.validation.correct = false;
      this.validation.range = false;
      this.validation.hasErrors = true;

      if (value) {
        const date = new Date(value);
        const dateArr = value.split('-');

        this.validation.correct = +dateArr[0] === date.getFullYear()
          && +dateArr[1] - 1 === date.getMonth()
          && +dateArr[2] === date.getDate();

        this.validation.range = +date >= this.availableDates[0]
          && date <= this.availableDates[1];

        this.validation.hasErrors = Object.keys(this.validation)
          .some((key) => key !== 'hasErrors' && !this.validation[key]);
      }

      this.$emit('validated', this.validation);
    },
  },
  watch: {
    inputModel: {
      handler(value) {
        this.validateDate(value);
      },
      immediate: true,
    },
    minDate: {
      handler(val) {
        this.config.year.min = val
          ? +val.split('-')[0]
          : null;
      },
      immediate: true,
    },
    maxDate: {
      handler(val) {
        this.config.year.max = val
          ? +val.split('-')[0]
          : null;
      },
      immediate: true,
    },
  },
}
</script>

<style lang="scss" scoped>
@import "./DateInput";
</style>
